import ApiService from "@/core/services/ApiService";
import { FormData as CpmiFormData } from "@/core/types/gws-global-feature/cpmi/FormData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { defineStore } from "pinia";

const authStore = useAuthenticationStore();
export const useMedicalApprovalFormStore = defineStore({
  id: "medicalApprovalFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
      stateCpmiData: {} as CpmiFormData,
      stateCpmiDetail: {} as CpmiFormData,
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
    cpmiDetail(state) {
      return state.stateCpmiDetail;
    }
  },
  actions: {
    async updateMedicalRequest(reject: boolean, approve: boolean) {
      this.stateIsLoading = true;
      try {
        const payload = {
          reject: reject,
          approve: approve,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/medical-service-application/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        if (metaResp) {
          metaResp.errors[0].forEach((err) => {
            this.stateErrors.push(err.message);
          });
        } else {
          this.stateErrors.push(error);
        }
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
